<template>
  <div>
    <a-modal
      :visible="visible.modalAddMarital"
      title="Buat Tunjangan Marital Baru"
      okText="Buat"
      @ok="handleCreateMarital"
      @cancel="handleCancel"
      :okButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
    >
      <div
        v-if="loading.add"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form
        v-else
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
      >
        <a-form-item label="Status">
          <a-input
            :value="this.dataMarital.status"
            placeholder="Status Marital"
            @change="(e) => handleAddMaritalChange(e.target.value, 'statusMarital')"
          />
        </a-form-item>
        <a-form-item label="Keterangan">
          <a-input
            :value="this.dataMarital.keterangan"
            placeholder="Keterangan Marital"
            @change="(e) => handleAddMaritalChange(e.target.value, 'keterangan')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :visible="visible.modalEditMarital"
      :confirm-loading="loading.confirm"
      @ok="handleOkEditMarital"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Perbaharui"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT
        MARITAL
      </h3>
      <p>
        Apakah anda yakin mau memperbarui tunjangan marital ini? Setelah diperbarui,
        data lama marital ini akan digantikan dengan data yang baru.
      </p>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="maritalSearch"
          @keyup="searchMarital"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Nama Marital...`"
        />
      </div>
      <div class="ml-auto">
        <template v-if="!edit.marital">
          <a-button
            class="mr-3"
            size="large"
            type="primary"
            @click.prevent="showModalAddMarital(true)"
            icon="plus"
            >Tambah</a-button
          >
          <a-button
            size="large"
            @click.prevent="toEditMarital"
            icon="edit"
            >Ubah</a-button
          >
        </template>
        <template v-else>
          <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
          >
          <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        v-if="edit.marital"
        :columns="maritalColumns"
        :data-source="maritalDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeMarital"
        bordered
      >
        <div slot="statusMarital" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditMaritalChange(e.target.value, record.key, 'statusMarital')
            "
          />
        </div>
        <div slot="keterangan" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditMaritalChange(e.target.value, record.key, 'keterangan')
            "
          />
        </div>
        <div slot="aksi" slot-scope="text, record">
          <a-button
            :disabled="edit.marital"
            size="large"
            class="text-danger border border-danger"
            :value="text"
            :loading="loading.delete"
            @click.prevent="(e) => deleteMarital(e.target.value, record.key)"
            ><a-icon type="delete" />Hapus</a-button
          >
        </div>
      </a-table>
      <a-table
        v-else
        :columns="maritalColumns"
        :data-source="maritalDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeMarital"
        bordered
      >
        <div slot="aksi" slot-scope="text, record">
          <a-button
            size="large"
            class="text-danger border border-danger"
            :value="text"
            :loading="loading.delete"
            @click.prevent="(e) => deleteMarital(e.target.value, record.key)"
            ><a-icon type="delete" />Hapus</a-button
          >
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const maritalColumns = [
  {
    title: 'Status Marital',
    dataIndex: 'statusMarital',
    key: 'statusMarital',
    width: 150,
    fixed: 'left',
    align: 'center',
    scopedSlots: { customRender: 'statusMarital' },
    sorter: true,
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    key: 'keterangan',
    scopedSlots: { customRender: 'keterangan' },
    width: 200,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Tunjangan',
    dataIndex: 'tunjangan',
    key: 'tunjangan',
    scopedSlots: { customRender: 'tunjangan' },
    align: 'center',
    children: [
      {
        title: 'Keluarga (%)',
        dataIndex: 'keluarga',
        key: 'keluarga',
        scopedSlots: { customRender: 'keluarga' },
        width: 200,
        align: 'center',
      },
      {
        title: 'Kepala Keluarga (%)',
        dataIndex: 'kepalaKeluarga',
        key: 'kepalaKeluarga',
        scopedSlots: { customRender: 'kepalaKeluarga' },
        width: 200,
        align: 'center',
      },
      {
        title: 'Kesehatan',
        dataIndex: 'kesehatan',
        key: 'kesehatan',
        scopedSlots: { customRender: 'kesehatan' },
        align: 'center',
        children: [
          {
            title: 'Laki-Laki (%)',
            dataIndex: 'lakiLaki',
            key: 'lakiLaki',
            scopedSlots: { customRender: 'lakiLaki' },
            width: 200,
            align: 'center',
          },
          {
            title: 'Perempuan (%)',
            dataIndex: 'perempuan',
            key: 'perempuan',
            scopedSlots: { customRender: 'perempuan' },
            width: 200,
            align: 'center',
          },
        ],
      },
    ],
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    width: 150,
    fixed: 'right',
    align: 'center',
  },
]

export default {
  name: 'master-marital',
  data() {
    return {
      maritalColumns,
      maritalDataTable: [],
      savedMaritalData: [],
      visible: {
        modalAddMarital: false,
        modalEditMarital: false,
      },
      loading: {
        table: false,
        add: false,
        delete: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      maritalPage: 'all',
      maritalSearch: '',
      maritalOrder: '',
      maritalSortBy: '',
      dataMarital: {
        status: null,
        keterangan: null,
      },
      edit: {
        marital: false,
      },
    }
  },
  methods: {
    handleEditMaritalChange(value, key, column) {
      const newData = [...this.maritalDataTable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        // console.log('target', target)
        target[column] = value
        this.savedMaritalData = newData
        // console.log('this.savedMaritalData', this.savedMaritalData)
      }
    },
    handleTableChangeMarital(pagination, filters, sorter) {
      // console.log('sorter', sorter)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'statusMarital') {
        sortBy = 'status_marital'
      } else {
        sortBy = ''
      }
      this.fetchDataMarital({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.add = boolean
      this.loading.delete = boolean
      this.loading.cancel = boolean
    },
    showModalAddMarital(boolean) {
      this.visible.modalAddMarital = boolean
    },
    searchMarital() {
      if (this.maritalSearch.length > 2) {
        this.fetchDataMarital({
          search: this.maritalSearch,
        })
      } else {
        this.fetchDataMarital({
          search: this.maritalSearch,
        })
      }
    },
    fetchDataMarital(
      params = {
        order: this.maritalOrder,
        sortBy: this.maritalSortBy,
        search: this.maritalSearch,
        page: this.maritalPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/GET_MARITAL_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.maritalDataTable = res.data.map((row) => {
            return {
              key: row.id,
              statusMarital: row.status_marital,
              keterangan: row.keterangan,
              keluarga: row.tunjangan_keluarga || 0,
              kepalaKeluarga: row.tunjangan_kepala_keluarga || 0,
              lakiLaki: row.laki_laki || 0,
              perempuan: row.perempuan || 0,
            }
          })
          this.savedMaritalData = this.maritalDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalAddMarital = false
      this.visible.modalEditMarital = false
    },
    handleAddMaritalChange(value, column) {
      if (column === 'statusMarital') {
        this.dataMarital.status = value
      }
      if (column === 'keterangan') {
        this.dataMarital.keterangan = value
      }
    },
    handleCreateMarital(value) {
      if ((this.dataMarital.status === '' || this.dataMarital.status === null) || (this.dataMarital.keterangan === '' || this.dataMarital.keterangan === null)) {
        this.$notification.error({
          message: 'Error',
          description: 'Harap mengisi semua data, tidak boleh ada data yang kosong!',
        })
        this.dataMarital.status = null
        this.dataMarital.keterangan = null
        this.visible.modalAddGolongan = false
      } else {
        this.handleAllLoading(true)
        this.$store
          .dispatch('adminHR/CREATE_MARITAL', {
            data: {
              status_marital: this.dataMarital.status,
              keterangan: this.dataMarital.keterangan,
            },
          })
          .then((status) => {
            this.fetchDataMarital()
            if (status === 200) {
              setTimeout(() => {
                this.$notification.success({
                  message: 'Sukses',
                  description: `Berhasil menambah marital baru ${this.dataMarital.status}`,
                })
              })
            } else {
              setTimeout(() => {
                this.$notification.error({
                  message: 'Gagal',
                  description: `Gagal menambah marital baru ${this.dataMarital.status}`,
                })
              })
            }
            this.handleAllLoading(false)
            this.dataMarital.status = null
            this.dataMarital.keterangan = null
            this.visible.modalAddGolongan = false
          })
      }
    },
    deleteMarital(value, key) {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>
            Apakah anda yakin ingin menghapus data marital ini? Setelah menghapus,
            data marital ini akan hilang!
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('adminHR/DELETE_MARITAL', {
              id: key,
            })
            .then((res) => {
              this.handleAllLoading(false)
              if (res === 200) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Marital berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Marital tidak berhasil dihapus',
                })
              }
              this.fetchDataMarital()
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    toEditMarital() {
      this.edit.marital = true
    },
    saveEdit() {
      this.visible.modalEditMarital = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataMarital()
      setTimeout(() => {
        this.edit.marital = false
        this.handleAllLoading(false)
      })
    },
    handleOkEditMarital() {
      const newData = [...this.savedMaritalData]
      if (newData.length) {
        for (let i = 0; i < newData.length; i++) {
          if (
            newData[i].statusMarital === '' ||
            newData[i].statusMarital === '-'
          ) {
            newData[i].statusMarital = null
          }
          if (
            newData[i].keterangan === '' ||
            newData[i].keterangan === '-'
          ) {
            newData[i].keterangan = null
          }
        }
        const target = newData.map((row) => {
          return {
            id: row.key,
            status_marital: row.statusMarital,
            keterangan: row.keterangan,
          }
        })
        // console.log('Berhasil', target)
        this.confirmLoading = true
        this.$store
          .dispatch('adminHR/UPDATE_MARITAL', {
            data: target,
          })
          .then((res) => {
            setTimeout(() => {
              this.fetchDataMarital()
              this.edit.marital = false
              this.visible.modalEditMarital = false
              this.handleAllLoading(false)
              if (res.isSuccess) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Marital berhasil diperbarui',
                })
              } else {
                this.$notification.error(
                  {
                    message: 'Gagal',
                    description: 'Marital tidak berhasil diperbarui',
                  },
                  1000,
                )
              }
            })
          })
      } else {
        this.edit.marital = false
        this.visible.modalEditMarital = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Marital tidak berhasil diperbarui',
          })
        })
      }
    },
  },
  mounted() {
    this.fetchDataMarital()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
